// material.module.ts

import { NgModule } from '@angular/core';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    MatTableModule,
	MatPaginatorModule,
	MatSortModule,
	MatInputModule,
	MatSelectModule,
	MatButtonModule,
	FormsModule,
	MatListModule,
	ReactiveFormsModule
  ],
  exports: [
    MatTableModule,
	MatPaginatorModule,
	MatSortModule,
	MatInputModule,
	MatSelectModule,
	MatButtonModule,
	FormsModule,
	MatListModule,
	ReactiveFormsModule
  ]
})

export class MaterialModule {}
