import { Component, OnInit, ViewChild  } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { ModalService } from './_modal';
import { HttpClient, HttpParams, HttpHeaders  } from '@angular/common/http';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import {FormBuilder, FormGroup, FormControl, FormGroupDirective, Validators} from '@angular/forms';
 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})




export class AppComponent {
	isTableExpanded = false;
	filterValues = {};
	minDate: Date;
  maxDate: Date;
  bsValue: Date;
  bsRangeValue: Date[];
  requestJSONVal;
  paginationJSONVal;
  servicecallapi;
  currentdate;
  startDate;
  currentItem = [];
  items = [];
  prevItems = [];
  pageId = 1;
/*  apiUrl = '/digital-fulfillment-services/report';
  logoutUrl = '/digital-fulfillment-services/report?logout=true';*/
/*  apiUrl = 'https://internal-estore-internal-alb-node-api-1409288993.us-west-2.elb.amazonaws.com/digital-fulfillment-services/report';*/
  apiUrl = 'https://stgwww2.agilent.com/digital-fulfillment-services/report';
  logoutUrl = 'https://stgwww2.agilent.com/digital-fulfillment-services/report?logout=true';
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSource = new MatTableDataSource();
  
  displayedColumns: string[] = ['detail', 'sapOrderNumber', 'orderStatus', 'emailAddress',  'creditHold', 'billingBlock', 'deliveryBlock','tradeHold', 'action','maction'];
  
  filterSelectObj = [];
 
  constructor(private modalService: ModalService,public httpClient: HttpClient,private formBuilder: FormBuilder) {
	//this.minDate = new Date();
	this.maxDate = new Date();
	this.bsValue = new Date();
	
    this.bsValue.setDate(this.bsValue.getDate() - 60);
	this.maxDate.setDate(this.maxDate.getDate() + 1);
	this.bsRangeValue = [this.bsValue, this.maxDate];
}

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
 // @HostListener('window:scroll')
  onScrollEvent() {
    this.datepicker.hide();
  }

  showPagination: boolean = false;
  showtable: boolean = false;
  showSapTextBox: boolean = false;
  showcancelmsg: boolean = false;
  showemailmsg: boolean = false;
  showLoading: boolean;
  sapValue: string = "";
  //hidesearchlabel: boolean = false;
  setLimit: number = 10;
  currentKey: string = "";
  previousKey: string = "";
  prevBtn: boolean = false;
  nextBtn: boolean = false;
  pageEvent: boolean = false;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
	this.dataSource.sort = this.sort;
	this.getRemoteData();
	this.resetFieldsValidation();

	this.empForm.get('searchbys').patchValue('orderStatus');
	this.empForm.get('orderFields').patchValue('Email Sent');
	
	
  }

  searchList = [
	{id: 'email', name: 'Email'},
	{id: 'partnumber', name: 'PartNumber'},
	{id: 'sapOrderNumber', name: 'Order Number'},
	{id: 'orderStatus', name: 'Order Status'}
];
selectedFieldList = this.searchList[3]['id']; 

orderStatusList = [
	{id: 'On Hold', name: 'On Hold'},
	{id: 'New', name: 'New'},
	{id: 'Invalid', name: 'Invalid'},
	{id: 'Ready to Deliver', name: 'Ready to Deliver'},
	{id: 'Email Sent', name: 'Software Download Email Sent'},
	{id: 'Cancelled', name: 'Cancelled'}
];
selectedorderStatusList = this.orderStatusList[4]; 

  empForm = this.formBuilder.group({
	searchbys: new FormControl(''),
	saporderfield: new FormControl(''),
	email: new FormControl(''),
	datetextbox: new FormControl(''),
	orderFields: new FormControl(''),
	partnumberfield: new FormControl('')
  });

 
  resetFieldsValidation () {
	
	const searchByControl1 = this.empForm.get('searchbys');
	const saporderfieldControl1 = this.empForm.get('saporderfield');
	const emailtextControl1 = this.empForm.get('email');
	const dateTextControl1 = this.empForm.get('datetextbox');
	const OrderStatusControl1 = this.empForm.get('orderFields');
	const partnumberfieldControl1 = this.empForm.get('partnumberfield');
	
	this.empForm.get('searchbys').valueChanges
      .subscribe(searchbys => {
		  this.showcancelmsg = false;
	this.showemailmsg = false; 
		if (searchbys === 'sapOrderNumber') {
			this.showSapTextBox = true;
		}
		else {
			this.showSapTextBox = false;
		}
		saporderfieldControl1.setValidators(null);
		dateTextControl1.setValidators(null);
	  OrderStatusControl1.setValidators(null);
	  partnumberfieldControl1.setValidators(null);
	  emailtextControl1.setValidators(null);
	  saporderfieldControl1.setValue('');
	  dateTextControl1.setValue('');
	  OrderStatusControl1.setValue('');
	  emailtextControl1.setValue('');
	  partnumberfieldControl1.setValue('');
        saporderfieldControl1.updateValueAndValidity();
        dateTextControl1.updateValueAndValidity();
		OrderStatusControl1.updateValueAndValidity();
		emailtextControl1.updateValueAndValidity();
		partnumberfieldControl1.updateValueAndValidity();
    });
  }

  edit(event,selMember){
    selMember.disableTextbox = !selMember.disableTextbox;
  }

  resetDefaultValues () {
	this.empForm.get('searchbys').patchValue('orderStatus');
	this.empForm.get('orderFields').patchValue('Email Sent');
  }
  

  setUserCategoryValidators() {
	const searchByControl = this.empForm.get('searchbys');
	const saporderfieldControl = this.empForm.get('saporderfield');
	const dateTextControl = this.empForm.get('datetextbox');
	const OrderStatusControl = this.empForm.get('orderFields');
	const emailtextControl = this.empForm.get('email');
	const partnumberfieldControl = this.empForm.get('partnumberfield');
	
	if(searchByControl.value != '') {
		if (searchByControl.value === 'sapOrderNumber') {
			saporderfieldControl.setValidators([Validators.required]);
			dateTextControl.setValidators(null);
		  OrderStatusControl.setValidators(null);
		  emailtextControl.setValidators(null);
		  partnumberfieldControl.setValue(null);
		}
		
		if (searchByControl.value === 'email') {
			emailtextControl.setValidators([Validators.required, Validators.email]);
			OrderStatusControl.setValidators(null);
			dateTextControl.setValidators(null);
			saporderfieldControl.setValidators(null);
			partnumberfieldControl.setValue(null);
		}

        if (searchByControl.value === 'partnumber') {
			partnumberfieldControl.setValidators([Validators.required]);
			OrderStatusControl.setValidators([Validators.required]);
			dateTextControl.setValidators(null);
			emailtextControl.setValidators(null);
			saporderfieldControl.setValue(null);
		}
		
		if (searchByControl.value === 'orderStatus') {
			dateTextControl.setValidators([Validators.required]);
			OrderStatusControl.setValidators([Validators.required]);
			saporderfieldControl.setValidators(null);
			emailtextControl.setValidators(null);
			partnumberfieldControl.setValue(null);
        }

        saporderfieldControl.updateValueAndValidity();
        dateTextControl.updateValueAndValidity();
		OrderStatusControl.updateValueAndValidity();
		emailtextControl.updateValueAndValidity();
		partnumberfieldControl.updateValueAndValidity();
	}
		
  }

  onFormSubmit(btnclick) {
	//this.pageEvent = false;
	this.pageId = 1;
	this.items = [];
	this.currentItem = [];
	this.setUserCategoryValidators();
	this.showcancelmsg = false;
	this.showemailmsg = false;
	const searchByControl = this.empForm.get('searchbys');
	
	if(searchByControl.value == undefined || searchByControl.value == '') {
		searchByControl.setValidators([Validators.required]);
		searchByControl.updateValueAndValidity();
	}
	if(this.empForm.value.searchbys == "email") {
		this.requestJSONVal = {
			"operationName": "SearchByEmail",
			"operationType": "Equals",
			"limit": this.setLimit,
			"parameters": [
				{
					"paramName": "Email",
					"paramValue": this.empForm.value.email.trim()
				}
			]
		}
		this.servicecallapi = "http://localhost:3000/api/emptyData";
	}
	if(this.empForm.value.searchbys == "partnumber") {
		this.requestJSONVal = {
			"operationName": "SearchByPartNumber",
			"operationType": "Equals",
			"limit": this.setLimit,
			"parameters": [
				{
					"paramName": "OrderStatus",
					"paramValue": this.empForm.value.orderFields
				},
				{
					"paramName": "partNo",
					"paramValue": this.empForm.value.partnumberfield.toUpperCase().trim()
				}
			]
		}
		this.servicecallapi = "http://localhost:3000/api/orderDetails";
	}
	if(this.empForm.value.searchbys == "sapOrderNumber") {
		this.requestJSONVal = {
			"operationName": "SearchBySAPOrderID",
			"operationType": "Equals",
			"limit": this.setLimit,
			"parameters": [
				{
					"paramName": "SAPOrderId",
					"paramValue": this.empForm.value.saporderfield.trim()
				}
			]
		}
		this.servicecallapi = "http://localhost:3000/api/forPagination4";
	}
	if(this.empForm.value.searchbys == "orderStatus" && this.empForm.value.datetextbox != '') {
			const day1 =this.empForm.value.datetextbox[0].getDate();
			const month1=this.empForm.value.datetextbox[0].getMonth();
			const year1=this.empForm.value.datetextbox[0].getFullYear();
			const day2 =this.empForm.value.datetextbox[1].getDate();
			const month2=this.empForm.value.datetextbox[1].getMonth();
			const year2=this.empForm.value.datetextbox[1].getFullYear();
	
		this.requestJSONVal = {
			"operationName": "SearchByOrderStatus",
			"operationType": "Equals",
			"limit": this.setLimit,
			"parameters": [
				{
					"paramName": "OrderStatus",
					"paramValue": this.empForm.value.orderFields
				},
				{
					"paramName": "startDate",
					"paramValue": new Date(year1, month1, day1).setHours(0,1,1)
				},
				{
					"paramName": "endDate",
					"paramValue": new Date(year2, month2, day2).setHours(23, 59, 59)
				}
			]
		}
		this.servicecallapi = "http://localhost:3000/api/forPagination5";
	}
	if(this.empForm.valid) {
		//this.hidesearchlabel = false;
		this.prevBtn = false;
		const formValue = this.requestJSONVal;
		const headers = new HttpHeaders().append('Content-Type', 'application/json');
		

		this.httpClient.post(this.apiUrl,formValue, {headers : headers}).subscribe((res:any)=>{
		//this.showLoading = true;	
		this.showSpinner('block');
			if(res.data.Count > 0) {
				this.showtable = true;
			}
			else {
				this.showtable = false;
			}
			
			if(res.data.LastEvaluatedKey == undefined) {
				this.showPagination = false;
				this.prevBtn = false;
			}
			else {
				this.showPagination = true;
				//this.currentKey = res.data.LastEvaluatedKey.sapOrderNumber;
				this.nextBtn = true;
				this.currentItem = res.data.LastEvaluatedKey;
			//	this.items.shift();
				this.items.push(res.data.LastEvaluatedKey); 
			}
			const remoteDummyData = res.data.Items;
			this.dataSource.data = remoteDummyData;
			//this.showLoading = false;
			this.showSpinner('none');
		}, err => {
			//throw err;
			if(err.status != 200) {
				window.location.href="https://stgwww.agilent.com/account/signout?aid=apsi";

			}	
		});
		
	}
	
	
  }

  logoutClick () {
	const headers = new HttpHeaders().append('Content-Type', 'application/json');
	
	this.httpClient.post(this.logoutUrl, {headers : headers}).subscribe((res:any)=>{
		if(res.data == "success") {
			window.location.href="https://dev-register.agilent.com/account/signout?aid=apsi";
			
		}
	});
  }

  nextPage (btnclick) {
	this.showcancelmsg = false;
	this.showemailmsg = false;
	this.pageEvent = true;
	
	if(this.empForm.value.searchbys == "email") {
		this.paginationJSONVal = {
			"operationName": "SearchByEmail",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.currentItem,
			"parameters": [
				{
					"paramName": "Email",
					"paramValue": this.empForm.value.email.trim()
				}
			]
		}
	}
	if(this.empForm.value.searchbys == "partnumber") {
		this.paginationJSONVal = {
			"operationName": "SearchByPartNumber",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.currentItem,
			"parameters": [
				{
					"paramName": "OrderStatus",
					"paramValue": this.empForm.value.orderFields
				},
				{
					"paramName": "partNo",
					"paramValue": this.empForm.value.partnumberfield.toUpperCase().trim()
				}
			]
		}
	}
	if(this.empForm.value.searchbys == "sapOrderNumber") {
		this.paginationJSONVal = {
			"operationName": "SearchBySAPOrderID",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.currentItem,
			"parameters": [
				{
					"paramName": "SAPOrderId",
					"paramValue": this.empForm.value.saporderfield.trim()
				}
			]
		}
	}
	if(this.empForm.value.searchbys == "orderStatus") {
		const day1 =this.empForm.value.datetextbox[0].getDate();
		const month1=this.empForm.value.datetextbox[0].getMonth();
		const year1=this.empForm.value.datetextbox[0].getFullYear();
		const day2 =this.empForm.value.datetextbox[1].getDate();
		const month2=this.empForm.value.datetextbox[1].getMonth();
		const year2=this.empForm.value.datetextbox[1].getFullYear();

		this.paginationJSONVal = {
			"operationName": "SearchByOrderStatus",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.currentItem,
			"parameters": [
				{
					"paramName": "OrderStatus",
					"paramValue": this.empForm.value.orderFields
				},
				{
					"paramName": "startDate",
					//"paramValue": Date.parse(this.empForm.value.datetextbox[0])
					"paramValue": new Date(year1, month1, day1).setHours(0,1,1)
				},
				{
					"paramName": "endDate",
					//"paramValue": Date.parse(this.empForm.value.datetextbox[1])
					"paramValue": new Date(year2, month2, day2).setHours(23, 59, 59)
				}
			]
		}
	}
	const tableValue = this.paginationJSONVal;
	const headers = new HttpHeaders().append('Content-Type', 'application/json');
	this.httpClient.post(this.apiUrl,tableValue, {headers : headers}).subscribe((res:any)=>{
		this.showSpinner('block');
		
		if(res.data.LastEvaluatedKey == undefined) {
			//this.showPagination = false;
			this.nextBtn = false;
			this.prevBtn = true;
			this.pageId = this.pageId +1;
		}
		else {
			//this.showPagination = true;
			this.nextBtn = true;
			this.prevBtn = true;
			this.currentItem = res.data.LastEvaluatedKey;
			this.pageId = this.pageId +1;  
			
			
			this.items.push(res.data.LastEvaluatedKey); 
			
		}
		const remoteDummyData = res.data.Items;
		this.dataSource.data = remoteDummyData;
		this.showSpinner('none');
	}, err => {
		//throw err;
		if(err.status != 200) {
			window.location.href="https://stgwww.agilent.com/account/signout?aid=apsi";

		}	
	});
	
  }
  prevPage (btnclick) {
	
	if(this.pageId == 2 || this.pageId == 1) {
		this.prevItems = [];
	}
	else {
		this.prevItems = this.items[this.pageId - 3];
	}
	if(this.empForm.value.searchbys == "email") {
		this.paginationJSONVal = {
			"operationName": "SearchByEmail",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.prevItems,
			"parameters": [
				{
					"paramName": "Email",
					"paramValue": this.empForm.value.email.trim()
				}
			]
		}
	}
	if(this.empForm.value.searchbys == "partnumber") {
		this.paginationJSONVal = {
			"operationName": "SearchByPartNumber",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.prevItems,
			"parameters": [
				{
					"paramName": "OrderStatus",
					"paramValue": this.empForm.value.orderFields
				},
				{
					"paramName": "partNo",
					"paramValue": this.empForm.value.partnumberfield.toUpperCase().trim()
				}
			]
		}
	}
	if(this.empForm.value.searchbys == "sapOrderNumber") {
		this.paginationJSONVal = {
			"operationName": "SearchBySAPOrderID",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.prevItems,
			"parameters": [
				{
					"paramName": "SAPOrderId",
					"paramValue": this.empForm.value.saporderfield.trim()
				}
			]
		}
	}
	if(this.empForm.value.searchbys == "orderStatus") {
		const day1 =this.empForm.value.datetextbox[0].getDate();
		const month1=this.empForm.value.datetextbox[0].getMonth();
		const year1=this.empForm.value.datetextbox[0].getFullYear();
		const day2 =this.empForm.value.datetextbox[1].getDate();
		const month2=this.empForm.value.datetextbox[1].getMonth();
		const year2=this.empForm.value.datetextbox[1].getFullYear();

		this.paginationJSONVal = {
			"operationName": "SearchByOrderStatus",
			"operationType": "Equals",
			"limit": this.setLimit,
			"LastEvaluatedKey":this.prevItems,
			"parameters": [
				{
					"paramName": "OrderStatus",
					"paramValue": this.empForm.value.orderFields
				},
				{
					"paramName": "startDate",
					//"paramValue": Date.parse(this.empForm.value.datetextbox[0])
					"paramValue": new Date(year1, month1, day1).setHours(0,1,1)
				},
				{
					"paramName": "endDate",
					//"paramValue": Date.parse(this.empForm.value.datetextbox[1])
					"paramValue": new Date(year2, month2, day2).setHours(23, 59, 59)
				}
			]
		}
	}
	if(this.pageId == 1 || this.pageId == 2) {
		delete this.paginationJSONVal['LastEvaluatedKey'];
	}
	const tableValue = this.paginationJSONVal;
	const headers = new HttpHeaders().append('Content-Type', 'application/json');
	this.httpClient.post(this.apiUrl,tableValue, {headers : headers}).subscribe((res:any)=>{
	
		this.showSpinner('block');
		
		if(res.data.LastEvaluatedKey == undefined) {
			//this.showPagination = false;
			this.prevBtn = false;
		}
		else {
			//this.showPagination = true;
			this.nextBtn = true;
			this.currentItem = res.data.LastEvaluatedKey;
			if(this.pageId > 1) {
				this.pageId = this.pageId - 1;  
			}
			
		
		}
		const remoteDummyData = res.data.Items;
		this.dataSource.data = remoteDummyData;
		this.showSpinner('none');
		if(this.pageId == 1) {
			this.prevBtn = false;
		}
	}, err => {
		//throw err;
		if(err.status != 200) {
			window.location.href="https://stgwww.agilent.com/account/signout?aid=apsi";
		}
	});

  }

  resetForm() {
	
	 this.showcancelmsg = false;
	this.showemailmsg = false; 
	//this.getRemoteData();
	//this.resetFieldsValidation();
	const searchByControl1 = this.empForm.get('searchbys');
	const saporderfieldControl1 = this.empForm.get('saporderfield');
	const emailtextControl1 = this.empForm.get('email');
	const dateTextControl1 = this.empForm.get('datetextbox');
	const OrderStatusControl1 = this.empForm.get('orderFields');
	const partnumberfieldControl1 = this.empForm.get('partnumberfield');

	searchByControl1.setValidators(null);
	saporderfieldControl1.setValidators(null);
	dateTextControl1.setValidators(null);
  	OrderStatusControl1.setValidators(null);
	partnumberfieldControl1.setValidators(null);
	emailtextControl1.setValidators(null);
	searchByControl1.setValue('');
	saporderfieldControl1.setValue('');
	dateTextControl1.setValue('');
	OrderStatusControl1.setValue('');
	emailtextControl1.setValue('');
	partnumberfieldControl1.setValue('');
	saporderfieldControl1.updateValueAndValidity();
	dateTextControl1.updateValueAndValidity();
	OrderStatusControl1.updateValueAndValidity();
	emailtextControl1.updateValueAndValidity();
	partnumberfieldControl1.updateValueAndValidity();
	searchByControl1.updateValueAndValidity();
  }
  
  openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
	}

	closeConfrimPopup(id: string) {
		this.modalService.close('confirm'+id);
	}

	onConfirm (sapid: string,emailid: string) {
		this.showemailmsg = false;
		this.sapValue = sapid;
		const headers = new HttpHeaders().append('Content-Type', 'application/json');
		const cancelOrderParams = {
			"operationName": "Cancel",
			"operationType": "Equals",
			"parameters": [
				{
					"paramName": "sapOrderNumber",
					"paramValue": sapid
				},
				{
					"paramName": "emailAddress",
					"paramValue": emailid
				}
			]
		}
		
		
		this.httpClient.post(this.apiUrl,cancelOrderParams, {headers : headers}).subscribe((res:any)=>{
			this.showSpinner('block');
			if(res.data == "success") {
				this.showcancelmsg = true;
				let element:HTMLElement = document.getElementById('btn-'+sapid) as HTMLElement;
				element.setAttribute("disabled", "disabled");
				let resendEmailelement:HTMLElement = document.getElementById('resend-btn-'+sapid) as HTMLElement;
				if(resendEmailelement != null) {
					resendEmailelement.setAttribute("disabled", "disabled");
				}
				this.modalService.close('confirm'+sapid);
				let scrollToTop = window.setInterval(() => {
					let pos = window.pageYOffset;
					if (pos > 0) {
						window.scrollTo(0, pos - 20);
					} else {
						window.clearInterval(scrollToTop);
					}
				}, 16);
			}
			else {
				this.showcancelmsg = false;
			}
			this.showSpinner('none');
		}, err => {
			//throw err;
			if(err.status != 200) {
				window.location.href="https://stgwww.agilent.com/account/signout?aid=apsi";
			}
		});
		
	}
	
	cancelOrder(sapid: string,emailid: string) {
		this.modalService.open("confirm"+sapid);
    }

    resendEmail(sapid: string,emailid: string) {
		this.showcancelmsg = false;
		this.sapValue = sapid;
		const headers = new HttpHeaders().append('Content-Type', 'application/json');
		const resendEmailParams = {
			"operationName": "ReSend",
			"operationType": "Equals",
			"parameters": [
				{
					"paramName": "sapOrderNumber",
					"paramValue": sapid
				}
			]
		}
		
		
		this.httpClient.post(this.apiUrl,resendEmailParams, {headers : headers}).subscribe((res:any)=>{
			this.showSpinner('block');
			if(res.data == "success") {
				this.showemailmsg = true;
				let scrollToTop = window.setInterval(() => {
					let pos = window.pageYOffset;
					if (pos > 0) {
						window.scrollTo(0, pos - 20); 
					} else {
						window.clearInterval(scrollToTop);
					}
				}, 16);
			}
			else {
				this.showemailmsg = false;
			}
			this.showSpinner('none');
		}, err => {
			//throw err;
			if(err.status != 200) {
				window.location.href="https://stgwww.agilent.com/account/signout?aid=apsi";
			}
		});
		
	}
    
  // Get remote serve data using HTTP call
  getRemoteData() {
	//this.hidesearchlabel = true;
	this.showcancelmsg = false;
	this.showemailmsg = false;  
	const days = 60; // Days you want to subtract
	const OrderStatusValue = "Email Sent";
	this.currentdate = new Date();
	const last = new Date(this.currentdate.getTime() - (days * 24 * 60 * 60 * 1000));
	const day =last.getDate();
	const month=last.getMonth();
	const year=last.getFullYear();
	this.startDate = new Date(year, month, day);
	this.currentdate.setHours(23, 59, 59);
	this.startDate.setHours(0, 1, 1);
	const headers = new HttpHeaders().append('Content-Type', 'application/json');
	const initialLoad = {
		"operationName": "SearchByOrderStatus",
		"operationType": "Equals",
		"limit": this.setLimit,
		"parameters": [
			{
				"paramName": "OrderStatus",
				"paramValue": OrderStatusValue
			},
			{
				"paramName": "startDate",
				"paramValue": Date.parse(this.startDate)
			},
			{
				"paramName": "endDate",
				"paramValue": Date.parse(this.currentdate)
			}
		]
	}
	
	
	this.httpClient.post(this.apiUrl,initialLoad, {headers : headers}).subscribe((res:any)=>{
		this.showSpinner('block');
		if(res.data.Count > 0) {
			this.showtable = true;
		}
		else {
			this.showtable = false;
		}
		
		if(res.data.LastEvaluatedKey == undefined) {
			this.showPagination = false;
			this.prevBtn = false;
			this.nextBtn = false;
		}
		else {
			this.showPagination = true;
			this.nextBtn = true;
			this.currentItem = res.data.LastEvaluatedKey;
			this.items.push(res.data.LastEvaluatedKey); 
		}
		const remoteDummyData = res.data.Items;
		this.dataSource.data = remoteDummyData;
		this.showSpinner('none');
	}, err => {
		//throw err;
		if(err.status != 200) {
			window.location.href="https://stgwww.agilent.com/account/signout?aid=apsi";
		}
	});
  }

  showSpinner(displayVal) {
	let element:HTMLElement = document.getElementById('loading') as HTMLElement;
	element.setAttribute("style","display:"+displayVal);
  }
}




