<div class="atgHeaderDiv">
	<div class="atgHeader">
		<span>	
			<a href="//www.agilent.com"><img src="//www.agilent.com/cs/agilent_images/icon-agilent-logo-white-white-2x.png" alt="Agilent Technologies" width="266" height="62"><img class="transparent-img" style="cursor:default" src="//www.agilent.com/cs/agilent_images/Transparent_Tagline_image.png" alt="Premier Laboratory Partner for a Better World" width="191" height="62"></a>
		</span>
		<span class="logoutSection"><a (click)="logoutClick()" href="javascript:void(0);">Logout</a></span>
	</div>
</div>
<div class="contentWrapper">
	<h1 class="pageTitle">AppStore Reporting UI</h1>
	<div class="reportSearch">
		
		<form [formGroup]="empForm" (ngSubmit)="onFormSubmit('form')">
			<div class="fieldBlock">
				<mat-form-field>
					<mat-label>Search By</mat-label>
					<mat-select #searchwith [(value)]="selectedFieldList" formControlName="searchbys">
						<mat-option *ngFor="let searchby of searchList" [value]="searchby.id">
							{{ searchby.name }}
						</mat-option>
					</mat-select>
					
				</mat-form-field>
			</div>	
		  <div class="fieldBlock"  *ngIf='selectedFieldList === "sapOrderNumber"'>
			<mat-form-field>
				<input matInput type="text" placeholder="Enter Order Number" formControlName="saporderfield">
			</mat-form-field>
		  </div> 
			<div class="fieldBlock emailField"  *ngIf='selectedFieldList === "email"'>
			<mat-form-field>
				<input matInput type="email" id="email" formControlName="email" placeholder="Enter Email"/>
			</mat-form-field>
		  </div> 
			<div class="fieldBlock"  *ngIf='selectedFieldList === "partnumber"'>
			<mat-form-field>
				<input matInput type="text" id="partnumberfield" formControlName="partnumberfield" placeholder="Enter PartNumber"/>
			</mat-form-field>
		  </div> 
			<div class="fieldBlock"  *ngIf='selectedFieldList === "orderStatus" || selectedFieldList === "partnumber"'>
			<mat-form-field>
				<mat-label>Order Status</mat-label>
				<mat-select [(value)]="selectedorderStatusList" formControlName="orderFields">
					<mat-option *ngFor="let orderField of orderStatusList" [value]="orderField.id">
						{{ orderField.name }}
						</mat-option>
				</mat-select>
				
			</mat-form-field>
		  </div> 
			<div class="fieldBlock"  *ngIf='selectedFieldList === "orderStatus"'>
			<mat-form-field>
				<input matInput  #input placeholder="Choose Date Range" autocomplete="off" bsDaterangepicker [bsConfig]="{displayMonths : 1,showWeekNumbers:false, ranges: ranges}" formControlName="datetextbox" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="bsRangeValue">
			
			</mat-form-field> 
		  </div> 
			<button id="searchOrder" class="btn-stnd-medium">Search</button>
			<button class="btn-stnd-medium" type="button" (click)="resetForm()">Clear</button>
		</form>
		
	</div>
	<div id="loading" class="loading"></div>
	<div  class="mat-elevation-z8 orderreport">
		<div class="successMsg msg-stnd msg-box-success" id="cancelOrderSuccessMsg" [ngStyle]="{display: showcancelmsg ? 'block' : 'none'}">
			<i class="fal fa-check-circle"></i><span>#{{sapValue}}: Order Cancelled Successfully</span>
		 </div>
		 <div class="successMsg msg-stnd msg-box-success" id="resendEmailSuccessMsg" [ngStyle]="{display: showemailmsg ? 'block' : 'none'}">
			<i class="fal fa-check-circle"></i><span>#{{sapValue}}: Email Resent successfully</span>
		 </div>
	<div class="tableOuterContainer" [ngStyle]="{display: showtable ? 'block' : 'none'}">
	  <table mat-table [dataSource]="dataSource" class="reportTable"  multiTemplateDataRows> <!-- matSort -->
		<ng-container matColumnDef="detail">
			<th class="mainTableHeader" mat-header-cell *matHeaderCellDef></th>
			<td class="detailsColumn" mat-cell *matCellDef="let element">
		  
			  <a href="javascript:void()" class="viewmore" (click)="openModal(element.sapOrderNumber)">+</a>
			</td>
		  </ng-container>
		<ng-container matColumnDef="sapOrderNumber">
		  <th class="mainTableHeader" mat-header-cell *matHeaderCellDef> Order Number </th>
		  <td mat-cell *matCellDef="let element"> {{element.sapOrderNumber}} </td>
		</ng-container>

		<ng-container matColumnDef="orderStatus">
		  <th class="mainTableHeader orderStatusColumn" mat-header-cell *matHeaderCellDef> Order Status </th>
		  <ng-container *matCellDef="let element">
			<td *ngIf="(element.orderStatus != 'Email Sent')" class="orderStatusColumn"> {{element.orderStatus}} </td>
			<td *ngIf="(element.orderStatus == 'Email Sent')" class="orderStatusColumn"> Software Download Email Sent </td>
		  </ng-container>
		</ng-container>

		<ng-container matColumnDef="emailAddress">
		  <th class="emailColumn mainTableHeader" mat-header-cell *matHeaderCellDef> Email Address </th>
		  <td mat-cell class="emailColumn" *matCellDef="let element"> <a class="email" href="mailto:{{element.emailAddress}}">{{element.emailAddress}}</a> </td>
		</ng-container>

		

		<ng-container matColumnDef="creditHold">
		  <th class="mainTableHeader" mat-header-cell *matHeaderCellDef> Credit Hold </th>
		  <td mat-cell *matCellDef="let element"> {{element.creditHold}} </td>
		</ng-container>
		
		<ng-container matColumnDef="billingBlock">
		  <th class="mainTableHeader" mat-header-cell *matHeaderCellDef> Billing Block </th>
		  <td mat-cell *matCellDef="let element"> {{element.billingBlock}} </td>
		</ng-container>

		<ng-container matColumnDef="deliveryBlock">
			<th class="mainTableHeader" mat-header-cell *matHeaderCellDef> Delivery Block </th>
			<td mat-cell *matCellDef="let element"> {{element.deliveryBlock}} </td>
		  </ng-container>

		<ng-container matColumnDef="tradeHold">
		  <th class="mainTableHeader" mat-header-cell *matHeaderCellDef> Trade Hold </th>
		  <td mat-cell *matCellDef="let element"> {{element.tradeHold}} </td>
		</ng-container>
		
		
		
		 <ng-container matColumnDef="action">
		  <th class="mainTableHeader actionCloumn" mat-header-cell *matHeaderCellDef> Order Action </th>
		  <ng-container *matCellDef="let element">
			<td *ngIf="(element.orderStatus == 'Cancelled')" class=""></td>
			<td *ngIf="(element.orderStatus != 'Cancelled')" class="cancel-btn"><button (click)="cancelOrder(element.sapOrderNumber,element.emailAddress)" id="btn-{{element.sapOrderNumber}}" class="cancelorderStatus btn-stnd-medium">Cancel/Return Order</button></td><!-- [disabled]=element.disableTextbox (click)="edit($event,element)"-->
		  </ng-container>
		</ng-container>

		<ng-container matColumnDef="maction">
			<th class="mainTableHeader actionCloumn" mat-header-cell *matHeaderCellDef> Email Action </th>
			<ng-container *matCellDef="let element">
				<td *ngIf="(element.orderStatus != 'Email Sent')" class="cancelresend-btn"></td>
			  <td *ngIf="(element.orderStatus == 'Email Sent')" class="cancelresend-btn"><button (click)="resendEmail(element.sapOrderNumber,element.emailAddress)" id="resend-btn-{{element.sapOrderNumber}}" class="resendorderStatus btn-stnd-medium">Resend Email</button></td>
			</ng-container>
		  </ng-container>
		
		  <ng-container matColumnDef="expandedDetail">
			<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
			  <div class="row student-element-detail"
				   [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
				   <jw-modal id="{{element.sapOrderNumber}}">
						<div class="modal-header">
							<span (click)="closeModal(element.sapOrderNumber);" class="newCloseIcon">
								<i class="fal fa-times"></i>
							</span>
							<h3 class="modal-title">Order Details</h3>
						</div>
						<div class="modal-container">
							<h5 class="SectionTitle">Summary</h5>
							<div class="tableOuterContainer">
								<table class="ItemDetails">
									<tr>
										<th>Login Name</th>
										<th>Delivery Method</th>
										<th>Ecom Status</th>
										<th>Member Type</th>
										<th>Payment Method</th>
										<th>Sales Org</th>
										<th>Sap ContactNum</th>
										<th>Shipment Type</th>
										<th>Shop CartId</th>
										<th>Total Price</th>
									</tr>
									<tbody>
									  <tr class="example-element-row">
										<td>{{element.loginName}}</td>
										<td>{{element.deliveryMethod}}</td>
										<td>{{element.ecomStatus}}</td>
										<td>{{element.memberType}}</td>
										<td>{{element.paymentMethod}}</td>
										<td>{{element.salesOrg}}</td>
										<td>{{element.sapContactNum}}</td>
										<td>{{element.shipmentType}}</td>
										<td>{{element.shopCartId}}</td>
										<td>{{element.totalPrice}}</td>
									  </tr>
									  </tbody>
								</table>
							</div>
							<h5 class="SectionTitle">Part Number Details</h5>
							<div class="tableOuterContainer">
								<table class="ItemDetails">
									<tr>
										<th width="10%">Part Number</th>
										<th width="30%" class="descColumn">Description</th>
										<th width="10%">E-Delivery</th>
										<th width="10%">Delivery Date</th>
										<th width="10%">Price</th>
										<th width="10%">PL</th>
										<th width="10%">Qty</th>
										<th width="10%">Total</th>
									</tr>
									<tbody>
									  <tr class="example-element-row"  *ngFor="let item of element.items">
										<td width="10%">{{item.partNumber}}</td>
										<td width="30%" class="descColumn">{{item.description}}</td>
										<td width="10%">{{item.requireElectronicDelivery}}</td>
										<td width="10%">{{item.deliveryDate  | date: 'short'}}</td>
										<td width="10%">{{item.listPrice}} {{item.currency}}</td>
										<td width="10%">{{item.PL}}</td>
										<td width="10%">{{item.qty}}</td>
										<td width="10%">{{item.total}}</td>
									  </tr>
									  </tbody>
								</table>
							</div>
							<h5 class="SectionTitle">Address Details</h5>
							
							<div class="addressDetails">
								<div class="address-left"  *ngFor="let addr of element.addresses">
									<p>Shipping Info : <span>{{addr.addnShippingInfo}}</span></p>
									<p>Address Type: <span>{{addr.addressType}}</span></p>
									<p>Attention: <span>{{addr.attention}}</span></p>
									<p>City: <span>{{addr.city}}</span></p>
									<p>CompanyName: <span>{{addr.companyName}}</span></p>
									<p>Country: <span>{{addr.country}}</span></p>
									<p>FedexNumber: <span>{{addr.fedexNumber}}</span></p>
									<p>Name: <span>{{addr.firstName}}</span></p>
									<p>MobileNumber: <span>{{addr.mobileNumber}}</span></p>
									<p>PhoneNumber: <span>{{addr.phoneNumber}}</span></p>
									<p>PostalCod: <span>{{addr.postalCode}}</span></p>
									<p>Region: <span>{{addr.region}}</span></p>
									<p>SapNumber: <span>{{addr.sapNumber}}</span></p>
									<p>SpecialInstructions: <span>{{addr.specialInstructions}}</span></p>
									<p>Street: <span>{{addr.street}}</span></p>
								</div>
							</div>
						</div>
						
					
						
					</jw-modal>
					<jw-modal class="confirmModalPopup" id="confirm{{element.sapOrderNumber}}">
						<div class="modal-header">
							<h3 class="modal-title">Cancel order</h3>
						</div>	
						<div class="modal-container">
							<div> 
								<p>Are you sure you want to cancel this order?</p>
							 </div>
							<div>
								<button class="btn-stnd-medium" (click)="onConfirm(element.sapOrderNumber,element.emailAddress)">Yes</button>
								<button class="btn-stnd-medium" (click)="closeConfrimPopup(element.sapOrderNumber)">No</button>
							</div>
						</div>
					</jw-modal>
					
			  </div>
			  
			</td>
		  </ng-container>

		  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		  <tr mat-row *matRowDef="let element; columns: displayedColumns;"
			  class="example-element-row"
			  [class.example-expanded-row]="element.isExpanded">
		  </tr>
		  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>

	  </table></div>
	 
		<div class="customPagination" [ngStyle]="{display: showPagination ? 'block' : 'none'}">
			<span id="prev-btn" [ngClass]="prevBtn ? 'enablebtn' : 'disablebtn'" (click)="prevPage('page')"><i class="fal fa-backward"></i> Prev</span>
			<span id="next-btn" [ngClass]="nextBtn ? 'enablebtn' : 'disablebtn' " (click)="nextPage('page')" >Next <i class="fal fa-forward"></i></span>
		</div>  
	  </div>
	  <div class="noItemsSection" id="noItems" [ngStyle]="{display: showtable ? 'none' : 'block'}">
		<p>NO ITEMS FOUND</p>
	 </div>
</div>
<div class="atgFooterDiv">	
	<div class="atgFooterBlue"></div>	
	<div class="atgFooter">		
		<div class="legal"> 			
			<span>
			<a data-link-name="PrivacyStatement" href="//www.agilent.com/home/privacy-policy">Privacy Statement</a>
			</span> |			
			<span>
			<a data-link-name="TermsOfUse" href="//www.agilent.com/home/terms-of-use">Terms of Use</a>
			</span> |			
			<span>
			<a data-link-name="ContactUs" href="//www.agilent.com/en-us/contact-us/page">Contact Us</a></span> 			
			<span class="copyRight">  © <span id="homeyear">
			<script type="text/javascript">document.write(new Date().getFullYear());</script>2020</span> Agilent Technologies All Rights Reserved. </span> 		
		</div>	
	</div>
</div>
